<template>
  <b-row class="mb-1">
    <b-col md="4">
      <b-form-group>
        <label>Societé</label>
        <v-select
          v-model="filter.societe"
          label="societe"
          placeholder="Sélectionnez une société"
          :options="societes"
        >
          <div slot="no-options">
            Option inexistante
          </div>
        </v-select>
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group>
        <label>Candidat</label>
        <b-form-input
          v-model="filter.candidat"
          type="text"
          class="d-inline-block"
        />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group label="Fonction">
        <v-select
          v-model="filter.fonction"
          :options="getFonctions"
          multiple
        />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group label="Compétence">
        <v-select
          v-model="filter.competence"
          :options="getCompetences"
          multiple
        />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group label="Date de création du">
        <b-form-datepicker
          v-model="filter.dateDebut"
          locale="fr-FR"
          hide-header
          reset-button
          menu-class="w-100"
          calendar-width="100%"
          class="mb-1"
          placeholder=""
          label-reset-button="Reinitialiser"
        />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group label="au">
        <b-form-datepicker
          v-model="filter.DateFin"
          locale="fr-FR"
          hide-header
          placeholder=""
          reset-button
          menu-class="w-100"
          calendar-width="100%"
          class="mb-2"
          label-reset-button="Reinitialiser"
        />
      </b-form-group>
    </b-col>

    <b-col
      md="12"
      class="text-right"
    >
      <b-button
        variant="primary"
        type="button"
        @click="rechercher"
      >
        Rechercher
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton, BForm, BFormDatepicker,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BButton,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    filter: {
      type: Object,
      default() {
        return {
          candidat: '',
          fonction: null,
          competence: null,
          dateDebut: null,
          dateFin: null,
          societes: null,
        }
      },
    },
  },
  data() {
    return {
      competences: [],
      fonctions: [],
    }
  },
  computed: {
    getCurrentUser() {
      return this.$store.getters['auth/currentUser']
    },
    getFonctions() {
      return this.fonctions?.map(elem => ({
        id: elem.id,
        label: elem.libelle_fonction,
      }))
    },
    getCompetences() {
      return this.competences?.map(elem => ({
        id: elem.id,
        label: elem.libelle_competence,
      }))
    },
    societes() {
      return this.$store.getters['user/getSocietes']
    },
  },
  beforeMount() {
    this.$store.dispatch('fonctionCompetence/getAllCompetences', { actif: 'Actif' }).then(
      data => {
        this.competences = data
      },
    )
    this.$store.dispatch('fonctionCompetence/getAllFonctions', { actif: 'Actif' }).then(
      data => {
        this.fonctions = data
      },
    )
    this.$store.dispatch('user/getAllSocietes', { id_user: this.getCurrentUser.id })
  },
  methods: {
    rechercher() {
      this.$emit('rechercher', { filter: this.filter })
    },
  },
}
</script>
