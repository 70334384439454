<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-card v-if="id != null && evaluation !== null">
        <b-card-title>
          <b-row class="justify-content-end">
            <b-col sm="9">
              <h3>Questionnaire d'évaluation des compétences</h3>
            </b-col>
            <b-col
              sm="3"
              class="text-right"
            >
              <b-button
                variant="primary"
                @click="detail()"
              >
                Retour
              </b-button>
            </b-col>
          </b-row>
        </b-card-title>
        <b-card-body class="mt-1">
          <b-row class=" mb-1">

            <b-col sm="8">
              <h4>
                <feather-icon icon="AlertCircleIcon" />
                Informations générales
              </h4>
            </b-col>
            <b-col
              sm="4"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.infos
                variant="flat-primary"
                @click="setinfosHide()"
              >
                <span v-if="infosHide === true"><feather-icon icon="ChevronDownIcon" /></span>
                <span v-else><feather-icon icon="ChevronLeftIcon" /></span>
              </b-button>
            </b-col>
            <b-collapse
              id="infos"
              visible
            >
              <b-col md="12">
                <span class="font-weight-bolder">Candidat : </span><span>{{ evaluation.evaluation.candidat }}</span>
              </b-col>
              <b-col md="12">
                <span class="font-weight-bolder">Société : </span><span>{{ evaluation.evaluation.societe }}</span>
              </b-col>
              <b-col md="12">
                <span class="font-weight-bolder">Agence : </span><span>{{ evaluation.evaluation.agence || '/' }}</span>
              </b-col>
              <b-col md="12">
                <span class="font-weight-bolder">Fonction : </span><span>{{ evaluation.evaluation.fonction.libelle_fonction }}</span>
              </b-col>
              <b-col md="12">
                <span class="font-weight-bolder">Compétence : </span><span>{{ evaluation.evaluation.competence.libelle_competence }}</span>
              </b-col>
            </b-collapse>
          </b-row>

          <hr>

          <p>
            Fait le {{ evaluation.evaluation.date | luxon }} selon les référentiels : {{ evaluation.evaluation.competence.documentation }}
          </p>
          <hr>

          <b-row>
            <b-col sm="8">
              <h4>
                <feather-icon icon="CheckCircleIcon" />
                Détails de vos réponses
              </h4>
            </b-col>
            <b-col
              sm="4"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.questions
                variant="flat-primary"
                @click="setQuestionHide()"
              >
                <span v-if="questionsHide"><feather-icon icon="ChevronDownIcon" /></span>
                <span v-else><feather-icon icon="ChevronLeftIcon" /></span>
              </b-button>
            </b-col>

          </b-row>

          <b-collapse id="questions">
            <liste-questions-reponse
              :questions="evaluation.questions"
              :reponses="evaluation.reponses"
              @set-total="setTotal"
              @total-reponse="setTotalReponse"
            />
          </b-collapse>

          <score-evaluation
            :series="series"
            :note="totalEvaluation"
            :reponsesko="totalReponsesErreurEvaluation"
            :reponsesok="totalReponsesCorrectEvaluation"
            :reponsesattendu="totalReponsesCorrectAttenduEvaluation"
            :questions="evaluation.questions.length"
          />

          <b-row>

            <b-col
              offset-md="8"
              class="text-right"
            >
              <b-button
                v-if="evaluation.evaluation.etat === 'terminee'"
                class="btn-sm mr-1"
                variant="primary"
                :disabled="waitDownload(id)"
                @click="generatePDF(id)"
              >
                <b-spinner
                  v-if="waitDownload(id)"
                  :key="id"
                  small
                />
                <span v-else>
                  <feather-icon
                    icon="FileTextIcon"
                  />
                  Télécharger le rapport
                </span>
              </b-button>
            </b-col>

            <b-col sm="12">
              <h3>Analyse détaillée de votre performance</h3>
              <p>
                Une analyse détaillée comprenant la correction du questionnaire, des commentaires sur les erreurs et des propositions d'actions d'amélioration, peut être réalisée en cliquant sur l'icône ci-dessous.
                Cette analyse sera réalisée par un expert en qualité et en contrôle des réseaux d'assainissement au sein de SQE Services et un rapport personnalisé vous sera retourné par mail.
                L'analyse détaillée a un coût de 80€ HT. La mise en oeuvre des actions d'amélioration proposées pourra faire l'objet d'un devis séparé sur demande.
              </p>
            </b-col>

            <b-col
              offset-md="8"
              class="text-right"
            >
              <b-button
                variant="primary"
                class="btn-sm mr-1"
                @click="envoyerDemande(id)"
              >
                <b-spinner
                  v-if="envoiDemande"
                  small
                />
                <span v-else>
                  <feather-icon icon="SendIcon" />
                  Envoyer la demande
                </span>
              </b-button>
            </b-col>

          </b-row>

        </b-card-body>
      </b-card>

      <b-card v-else>
        <b-card-title>
          <b-row class="justify-content-end">
            <b-col sm="9">
              <h3>Mes évaluations</h3>
            </b-col>
            <b-col
              sm="3"
              class="text-right"
            >
              <b-button
                v-if="utilisateur.solde > 0"
                variant="primary"
                :to="{ path: 'nouvelle-evaluation' }"
              >
                Nouvelle évaluation
              </b-button>
            </b-col>
          </b-row>

        </b-card-title>
        <b-card-body class="mt-1">
          <filtres
            :filter="filter"
            @rechercher="updateListing"
          />

          <vue-good-table
            :columns="columns"
            :rows="rows"
            :sort-options="{
              enabled: false,
            }"
            class="table b-table b-table-fixed"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field == 'actions'"
              >
                <b-button
                  v-if="props.row.etat === 'terminee'"
                  class="btn-sm mr-1"
                  variant="primary"
                  :disabled="waitDownload(props.row.id)"
                  @click="generatePDF(props.row.id)"
                >
                  <b-spinner
                    v-if="waitDownload(props.row.id)"
                    :key="props.row.id"
                    small
                  />
                  <feather-icon
                    v-else
                    icon="FileTextIcon"
                  />
                </b-button>

                <b-button
                  class="btn-sm"
                  variant="primary"
                  @click="detail(props.row.id)"
                >
                  <feather-icon
                    icon="SearchIcon"
                  />
                </b-button>
                <br>
                <b-badge
                  class="mt-1"
                  :variant="getEtat(props.row, 'variant')"
                >
                  {{ getEtat(props.row, 'libelle') }}
                </b-badge>
                <!-- <ModalUpdate /> -->
              </span>
              <span v-else-if="props.column.field == 'fonction'">
                <span v-if="props.row.fonction !== null">{{ props.row.fonction.libelle_fonction }}</span>
              </span>
              <span v-else-if="props.column.field == 'competence'">
                <span v-if="props.row.competence !== null">{{ props.row.competence.libelle_competence }}</span>
              </span>
            </template>
          </vue-good-table>

          <b-row class="mt-2">
            <b-col
              cols="6"
              class="text-left"
            >
              <b-form-group
                class="mb-0"
              >
                <label class="d-inline-block text-sm-left mr-50">Afficher</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-25"
                />
                <span class="ml-2">sur {{ totalRows }} lignes</span>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="sm"
                class="my-0"
              />
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BCard, BCardTitle, BCardBody, BRow, BCol, BButton, BCollapse, VBToggle, BBadge, BPagination, BFormSelect, BFormGroup,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Filtres from './evaluation/filter.vue'
import listeQuestionsReponse from './evaluation/listeQuestionReponse.vue'
import ScoreEvaluation from './evaluation/score.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BCollapse,
    BBadge,
    Filtres,
    VueGoodTable,
    listeQuestionsReponse,
    FeatherIcon,
    ScoreEvaluation,
    BPagination,
    BFormSelect,
    BFormGroup,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      loading: false,
      questionsHide: true,
      infosHide: false,
      id: null,
      evaluation: null,
      totalEvaluation: 0,
      totalReponsesErreurEvaluation: 0,
      totalReponsesCorrectEvaluation: 0,
      totalReponsesCorrectAttenduEvaluation: 0,
      isDownloading: [],
      filter: {
        candidat: '',
        fonction: null,
        competence: null,
        dateDebut: null,
        dateFin: null,
        societe: null,
      },
      columns: [
        { field: 'actions', tdClass: 'text-center actions' },
        { label: '#', field: 'id' },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        { label: 'Société', field: 'societe' },
        { label: 'Candidat', field: 'candidat' },
        { label: 'Fonction', field: 'fonction' },
        { label: 'Compétence', field: 'competence' },
      ],
      rows: [],
      series: [0],
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 25, 50],
      totalRows: 0,
      envoiDemande: false,
    }
  },
  computed: {
    utilisateur() { return this.$store.getters['auth/currentUser'] },
  },
  watch: {
    perPage() {
      this.currentPage = 1
      this.updateListing()
    },
    currentPage() {
      this.updateListing()
    },
  },
  mounted() {
    const d = new Date()
    d.setMonth(d.getMonth() - 3)
    this.filter.dateDebut = `${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-01`
    this.updateListing()
  },
  methods: {
    setQuestionHide() {
      this.questionsHide = !this.questionsHide
    },
    setinfosHide() {
      this.infosHide = !this.infosHide
    },
    updateListing(filter) {
      if (filter) this.filter = filter.filter
      if (!this.utilisateur.isAdmin) this.filter.userId = this.utilisateur.id

      const params = this.filter
      params.limit = this.perPage
      params.offset = (this.currentPage - 1) * this.perPage

      this.$store.dispatch('evaluation/getAllEvals', params).then(data => {
        this.rows = data.evaluations
        this.totalRows = data.total
      })
    },
    generatePDF(id) {
      if (!this.waitDownload(id)) {
        const that = this
        this.isDownloading.push(id)
        this.$store.dispatch('evaluation/generatePDF', { id }).then(pdfData => {
          const blob = new Blob([pdfData], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', `${id}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.isDownloading = this.isDownloading.filter(e => e !== id)
        })
          .catch(() => {
            that.$swal({
              width: 500,
              title: 'Recuperation du fichier impossible',
              text: "Une erreur technique s'est produite. Veuillez contacter le support SQE Services",
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.isDownloading = this.isDownloading.filter(e => e !== id)
          })
      }
    },
    detail(id) {
      this.loading = true
      this.infosHide = false
      this.questionsHide = true
      if (id) {
        this.$store.dispatch('evaluation/getEval', { id }).then(data => {
          this.evaluation = data
          this.loading = false
        })
        this.id = id
      } else {
        this.evaluation = null
        this.totalEvaluation = 0
        this.id = null
        this.loading = false
      }
    },
    setTotal(value) {
      this.totalEvaluation = value
      this.series = [((value / 20) * 100)]
    },
    setTotalReponse(data) {
      this.totalReponsesErreurEvaluation = data.ko
      this.totalReponsesCorrectEvaluation = data.ok
      this.totalReponsesCorrectAttenduEvaluation = data.attendu
    },
    getEtat(evaluation, type) {
      const retour = {
        libelle: '',
        variant: '',
      }
      // eslint-disable-next-line default-case
      switch (evaluation.etat) {
        case 'en_cours':
          retour.libelle = 'En cours'
          retour.variant = 'light-primary'
          break

        case 'abandonnee':
          retour.libelle = 'Abandonnée'
          retour.variant = 'light-danger'
          break

        case 'terminee':
          retour.libelle = 'Terminée'
          retour.variant = 'light-success'
          break
      }
      return retour[type]
    },
    waitDownload(id) {
      return this.isDownloading.includes(id)
    },
    envoyerDemande(id) {
      const that = this
      if (!this.envoyerDemande === false) {
        this.envoiDemande = true
        this.$store.dispatch('evaluation/demandeDetail', { id })
          .then(() => {
            that.$swal({
              title: 'Demande de rapport détaillé',
              text: 'SQE Services a été prévenu de votre demande, merci.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.envoiDemande = false
          })
          .catch(() => {
            that.$swal({
              width: 500,
              title: 'Demande de rapport détaillé',
              text: "Une erreur technique s'est produite. Veuillez contacter le support SQE Services",
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.envoiDemande = false
          })
      }
    },
  },
}
</script>

<style>
td.actions {
    min-width: 130px;
}
</style>
