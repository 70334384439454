<template>
  <div v-if="loading == false">
    <div
      v-for="(question, index) in questions"
      :key="index"
    >
      <b-row class="mt-1">
        <b-col
          sm="10"
          class="mb-1 font-weight-bold"
        >
          <span class="mr-2">Q{{ index+1 }}</span>
          <span>{{ question.categorie.libelle_categorie }} - {{ question.libelle }}</span>
        </b-col>

        <b-col
          sm="2"
          class="mb-1 font-weight-bold text-right"
        >
          <span class="text-muted mr-2">
            {{ refQuestion(question.id) }}
          </span>
          <span
            :class="getInfoPoint(question.id, 'class')"
          >{{ getInfoPoint(question.id, 'text') }}</span>

        </b-col>
      </b-row>

      <b-row >
        <b-col
          v-if="question.media_question"
          md="3"
          class="m-auto"
        >
          <img
            class="w-100"
            :src="question.media_question"
            alt=""
          >
        </b-col>
        <b-col
          v-if="question.video_question"
          md="4"
          class="m-auto"
        >
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="question.video_question"
            allowfullscreen
          />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          v-for="(reponseQuestion, indexReponse) in question.reponseQuestion"
          :key="indexReponse"
          md="3"
          class="border-right"
        >
          <b-row v-if="reponseQuestion.reponse.lien_media">
            <b-col
              md="6"
              class="m-auto pt-1 pb-1"
            >
              <img
                class="w-100"
                :src="reponseQuestion.reponse.lien_media"
                alt=""
              >
            </b-col>
          </b-row>
          <b-row
            cols-lg="0"
            class="mt-1"
            :class="getColor(question.id, reponseQuestion.reponseId, 'text-')"
          >
            <b-col
              sm="2"
              align-self="center"
            >
              <b-form-checkbox
                v-model="checkedReponse"
                name="is-vertical-menu-collapsed"
                class="mr-0"
                :class="getColor(question.id, reponseQuestion.reponseId, 'custom-control-')"
                :value="getValueReponse(question.id, reponseQuestion.reponseId)"
                disabled
              />
            </b-col>
            <b-col sm="8">
              <span class="font-weight-bold">{{ reponseQuestion.reponse.libelle }}</span>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
      <hr>

    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BEmbed,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BEmbed,
  },
  props: {
    questions: {
      type: Array,
      default: null,
    },
    reponses: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      checkedReponse: [],
      total: 0,
      points: {},
      loading: true,
    }
  },
  mounted() {
    const that = this
    let total = 0
    let reponseOk = 0
    let reponseKo = 0
    let reponseAttendu = 0

    this.questions.map(question => {
      const points = that.getPoint(question)
      total += parseFloat(points)
      that.points[question.id] = points
    })

    const reponsesCoche = this.reponses.filter(r => r.checked)
    // Si aucune reponse au questionnaires 0/20
    if (reponsesCoche.length === 0) {
      this.$emit('set-total', 0)
    } else {
      const score = (total / this.questions.length) * 20
      this.$emit('set-total', score)
    }

    this.reponses.map(reponse => {
      const checkedReponseForQuestion = this.reponses.filter(r => r.questionId === reponse.questionId).filter(r => r.checked)

      if (checkedReponseForQuestion.length > 0) {
        if (reponse.checked === 'true' || reponse.checked === true) {
          that.checkedReponse.push(`${reponse.questionId}-${reponse.reponseId}`)
        }
        if (reponse.correct) reponseAttendu += 1
        if (reponse.checked && !reponse.correct) reponseKo += 1
        if (reponse.correct && reponse.checked) reponseOk += 1
        if (reponse.correct && !reponse.checked) reponseKo += 1
      }

      return null
    })
    this.$emit('total-reponse', { ok: reponseOk, attendu: reponseAttendu, ko: reponseKo })
    this.loading = false
  },
  methods: {
    getReponseQuestion(idQuestion) {
      return this.reponses.filter(reponse => reponse.questionId === idQuestion)
    },
    getValueReponse(idQuestion, idReponse) {
      return `${idQuestion}-${idReponse}`
    },
    getColor(idQuestion, idReponse, prepend) {
      const reponsesQuestionChecked = this.reponses.filter(reponses => (reponses.questionId === idQuestion && reponses.checked))
      if (reponsesQuestionChecked.length > 0) {
        const res = this.reponses.filter(reponseClient => (reponseClient.questionId === idQuestion && reponseClient.reponseId === idReponse))
        if (res[0]) {
          if (res[0].correct) {
            return `${prepend}success`
          }
          return `${prepend}danger`
        }
        return `${prepend}info`
      }
      return ''
    },
    getInfoPoint(id, type) {
      const info = {
        text: '',
        class: 'text-warning',
      }
      if (this.points[id] === 0) {
        info.text = '0 point'
        info.class = 'text-danger'
      } else if (this.points[id] === 1) {
        info.text = '1 point'
        info.class = 'text-success'
      } else {
        info.text = `${this.points[id]} point`
      }
      return info[type]
    },
    getPoint(question) {
      let point = 0
      const totalReponse = question.reponseQuestion.length

      // Si aucune reponse à la question, 0/1
      const reponseDansQuestion = this.reponses.filter(reponseClient => (reponseClient.questionId === question.id && reponseClient.checked))

      if (reponseDansQuestion.length === 0) return 0

      // Sinon on calcul les points
      const res = this.reponses.filter(reponseClient => (reponseClient.questionId === question.id))
      res.forEach(reponse => {
        if (reponse.checked === reponse.correct) {
          point += 1 / totalReponse
        }
      })
      return point.toFixed(2)
    },
    refQuestion(id) {
      let ref = ''
      if (id < 10) ref = `00000${id}`
      else if (id < 100) ref = `0000${id}`
      else if (id < 1000) ref = `000${id}`
      else if (id < 10000) ref = `00${id}`
      else if (id < 100000) ref = `0${id}`
      else if (id < 100000) ref = `0${id}`
      else ref = id

      return `Q${ref}`
    },
  },

}
</script>
